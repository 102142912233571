<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="
              'https://api.optitmr.com/User_Pictures/' + optionsLocal.pictureurl
            "
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          {{ $t("upload") }}
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!--/ reset -->
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group :label="$t('name')" label-for="name">
            <b-form-input
              v-model="optionsLocal.name"
              :placeholder="$t('name')"
              name="name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('lastName')" label-for="surname">
            <b-form-input
              v-model="optionsLocal.surname"
              name="surname"
              :placeholder="$t('lastName')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('mobile')" label-for="account-mobile">
            <b-form-input
              v-model="optionsLocal.mobile"
              name="mobile"
              placeholder="+905555555555"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('companyName')" label-for="account-company">
            <b-form-input
              v-model="optionsLocal.companyname"
              name="company"
              :placeholder="$t('companyName')"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateUser"
          >
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: {},
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
    };
  },
  mounted() {
    this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.optionsLocal.imgstr = base64;
        this.$http
          .put(
            `/Users/UpdateUserPicture/${this.optionsLocal.id}`,
            JSON.stringify(base64),
            config
          )
          .then((result) => {
            if (result.data.status == "OK") {
              this.$http
                .get(`/Users/GetUserPictureFilename/${this.optionsLocal.id}`)
                .then(({ data }) => {
                  this.optionsLocal.pictureurl =
                    data.statusdata + "?" + Math.random();
                  this.$store.commit(
                    "app/UPDATE_PICTURE_URL",
                    data.statusdata + "?" + Math.random()
                  );
                });
            }
          });
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  methods: {
    updateUser() {
      let data = {
        id: this.optionsLocal.id,
        fullname: this.optionsLocal.name + " " + this.optionsLocal.surname,
        name: this.optionsLocal.name,
        surname: this.optionsLocal.surname,
        password: this.optionsLocal.password,
        mobile: this.optionsLocal.mobile,
        imgstr: this.picturechanged ? this.optionsLocal.imgstr : null,
        usertype: this.optionsLocal.usertype,
        usertypename: this.optionsLocal.usertypename,
        picturechanged: this.picturechanged,
        pictureurl: this.optionsLocal.pictureurl,
        ids: this.optionsLocal.ids.join(","),
        active: this.optionsLocal.active,
      };

      this.$http
        .put(`/Users/UpdateUser/${this.optionsLocal.id}`, data)
        .then((res) => {
          this.optionsLocal.pictureurl =
            this.optionsLocal.pictureurl + "?" + Math.random();
          this.$store.commit(
            "app/UPDATE_PICTURE_URL",
            this.optionsLocal.pictureurl + "?" + Math.random()
          );
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t("successful"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$t("updated", { type: this.$t("user") }),
            },
          });
        });
    },
  },
};
</script>
